import { Link } from '@remix-run/react'
import { RemixLinkProps } from '@remix-run/react/dist/components'
import useLanguage from '~/contexts/language/useLanguage'

const stripTrailingSlash = (str: string) => (str.length > 1 ? str.replace(/\/$/, '') : str)

export default function InternalLink({
  to,
  children,
  ...props
}: { to: string; children: React.ReactNode } & RemixLinkProps) {
  const lang = useLanguage()

  const url = stripTrailingSlash(
    `${lang !== 'en' && !to.startsWith('http') ? `/${lang}` : ''}${to}`
  )

  return (
    <Link to={url} {...props}>
      {children}
    </Link>
  )
}
